<template>
  <div id="centerRight1">
    <dv-border-box-12>
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-line" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="text mx-2">最新活动列表</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board class="dv-scr-board" :config="config" />
      </div>
    </div>
    </dv-border-box-12>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status:{
        '-1':'已取消',
        '0':'未开始',
        '1':'招募中',
        '2':'进行中',
        '3':'已完成'
      },
      config: {
        header: ['状态', '活动名称', '地点'],
        data: [],
        rowNum: 4, //表格行数
        headerHeight: 35,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        index: false,
        align: ['center','center','center']
      }
    }
  },
  created(){
    this.getList()
  },
  methods:{
    getList(){
      this.$request.get('/admin/v1/civilization/activity').then(res=>{
        res.data.forEach((e,i)=>{
          this.config.data.push([this.status[e.actState],e.orgName,e.actAddress])
        })
        this.config = { ...this.config }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$box-height: 305px;
$box-width: 100%;
#centerRight1 {
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    padding: 16px;
    height: 100%;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-scr-board {
      width: 100%;
      height: 245px;
    }
  }
}
</style>
