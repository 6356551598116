<template>
  <div id="centerLeft1">
  <dv-border-box-12>
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-bar" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="text mx-2">志愿者统计</span>
          <dv-decoration-3 class="dv-dec-3" />
        </div>
      </div>
      <div class="box">
        <div class="d-flex">
          <CenterLeft1Chart />
        </div>
        <!-- 4个主要的数据 -->
        <div class="bottom-data">
          <div
            class="item-box mt-2"
            v-for="(item, index) in numberData"
            :key="index"
          >
            <div class="d-flex">
              <dv-digital-flop class="dv-digital-flop" :config="item.number" />
            </div>
            <p class="text">
              {{ item.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </dv-border-box-12>
  </div>
</template>

<script>
import CenterLeft1Chart from '@/components/echart/centerLeft/centerLeft1Chart'
export default {
  data() {
    return {
      numberData: [
        {
          number: {
            number: [0],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: '总人数(人)'
        },
        // {
        //   number: {
        //     number: [0],
        //     toFixed: 0,
        //     textAlign: 'left',
        //     content: '{nt}',
        //     style: {
        //       fontSize: 24
        //     }
        //   },
        //   text: '总服务时长（时）'
        // },
      ]
    }
  },
  components: {
    CenterLeft1Chart
  },
  created() {
    this.changeTiming()
  },
  methods: {
    changeTiming() {
      this.changeNumber()
      setInterval(() => {
        this.changeNumber()
      }, 8000)
    },
    changeNumber() {
      this.$request.get('/admin/v1/civilization/Statistics/volunteers/amount').then(res=>{
        this.numberData[0].number.number[0] = res
        this.numberData[0].number = { ...this.numberData[0].number }
      })
      this.$request.get('/admin/v1/civilization/Statistics/volunteers/getTotalServiceDuration').then(res=>{
        this.numberData[1].number.number[0] = +(res/60).toFixed(2)
        this.numberData[1].number = { ...this.numberData[1].number }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 240px;
.box{
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}
#centerLeft1 {
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .bg-color-black {
    padding: 16px;
    height: 100%;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
    font-size: 16px;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .bottom-data {
    margin-top: 10px;
    .item-box {
      font-size: 14px;
      color: #d3d6dd;
      .dv-digital-flop {
        height: 50px;
      }
    }
  }
}
</style>
