<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">茂名市电白区新时代文明实践大数据中心</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
          <el-dropdown class="user">
            <span class="el-dropdown-link">
              {{ userInfo.username }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        

        <div class="body-box">
          <div class="left">
            <centerLeft1 />
            <centerRight2 />
            <bottomRight />
          </div>
          <div class="center">
            <center />
            <centerLeft2 />
            <centerRight1 />
          </div>
          <div class="right">
            <bottomLeft />
            <centerRight/>
            <bottomRight1 />
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'
import centerLeft1 from './centerLeft1'
import centerLeft2 from './centerLeft2'
import centerRight1 from './centerRight1'
import centerRight2 from './centerRight2'
import center from './center'
import bottomLeft from './bottomLeft'
import bottomRight from './bottomRight'
import bottomRight1 from './bottomRight1'
import centerRight from './centerRight.vue'

export default {
  mixins: [ drawMixin ],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000']
    }
  },
  components: {
    centerLeft1,
    centerLeft2,
    centerRight1,
    centerRight2,
    center,
    bottomLeft,
    bottomRight,
    bottomRight1,
    centerRight
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  mounted() {
    // this.timeFn()
    this.cancelLoading()
  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
    // timeFn() {
    //   this.timing = setInterval(() => {
    //     this.dateDay = formatTime(new Date(), 'HH: mm: ss')
    //     this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
    //     this.dateWeek = this.weekday[new Date().getDay()]
    //   }, 1000)
    // },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    logout(){
        this.$confirm('确认退出系统吗？', '退出', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('user/logout').then(()=>{
            this.$router.replace(`/login`)
          })
        }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
.user{
  position: absolute;
  right: 48px;
  top: 35px;
  color: #ffffff!important;
  font-size: 24px!important;
}
</style>
