<template>
  <div id="bottomRight">
    <dv-border-box-12>
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-area" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="text mx-2">服务点单统计</span>
          <div class="decoration2">
            <dv-decoration-2 :reverse="true" style="width:5px;height:6rem;" />
          </div>
        </div>
      </div>
      <div>
        <div class="total">服务点单<span>  {{ total }} </span>个</div>
        <BottomRightChart />
      </div>
    </div>
    </dv-border-box-12>
  </div>
</template>

<script>
import BottomRightChart from "@/components/echart/bottom/bottomRightChart";
export default {
  components: {
    BottomRightChart
  },
  data() {
    return{
      total: 0,
    }
  },
  mounted () {
    this.getData();
  },
  methods:{
    getData() {
      let params = {
        actType: 2
      }
      this.$request.get('/admin/v1/civilization/Statistics/activity/countByActState',{ params }).then(res=>{
        // this.total = res.unresolvedCount+res.pendingResolutionCount+res.resolvedCount+res.evaluatedCount;
        for(let item of res){
          if(item.actState==-1) continue
          this.total+=item.count
        }
      })
    },
  }
};
</script>

<style lang="scss" class>
$box-height: 415px;
$box-width: 100%;
#bottomRight {
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: 100%;
    border-radius: 10px;
    padding: 16px;
  }
  .text {
    color: #c3cbde;
  }
  //下滑线动态
  .decoration2 {
    position: absolute;
    right: 0.125rem;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
.total{
  color: #fff;
  margin: 20px 0 0 30px;
  font-size: 18px;
  span{
    color: #32baa7;
    font-size: 24px;
  }
}
</style>