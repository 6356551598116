<template>
  <div id="centerRight1">
    <dv-border-box-12>
    <div class="bg-color-black">
      <dv-border-box-8 class="tabs">
        <div class="d-flex">
          <span class="tabs-item" :class="{activeTab:activeTab===index}" v-for="(item,index) in tabList" :key="index" @click="onTab(index)">{{ item.name }}</span>
        </div>
      </dv-border-box-8>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board class="dv-scr-board" :config="config" />
      </div>
    </div>
  </dv-border-box-12>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ['排名', '姓名', '积分'],
        data: [],
        rowNum: 4, //表格行数
        headerHeight: 40,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        index: false,
        align: ['center','center','center']
      },
      activeTab:0,
      tabList:[
        {
          name:'志愿者排行'
        },{
          name:'志愿团队排行'
        },
      ]
    }
  },
  created(){
    this.onTab(0)
  },
  methods:{
    onTab(i){
      this.activeTab = i
      this.config.data = []
      switch (i) {
        case 0:
          this.getVolunteersList()
          break;
        case 1:
          this.getServiceTeamList()
          break;
        default:
          break;
      }
    },
    // 志愿者排行
    getVolunteersList(){
      this.$request.get('/api/v1/civilization/Statistics/volunteers/integralRank').then(res=>{
        res.forEach((e,i)=>{
          this.config.data.push([i+1,e.realname,e.accumulatedIntegral])
        })
        this.config = { ...this.config }
      })
    },
    // 志愿团队排行
    getServiceTeamList(){
      this.$request.get('/api/v1/civilization/Statistics/serviceTeam/integralRank').then(res=>{
        res.forEach((e,i)=>{
          this.config.data.push([i+1,e.teamName,e.totalIntegral])
        })
        this.config = { ...this.config }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$box-height: 253px;
$box-width: 100%;
#centerRight1 {
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    padding: 16px;
    height: 100%;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-scr-board {
      width: 100%;
      height: 179px;
    }
  }
}
.tabs{
  height: 50px;
  font-size: 18px;
  line-height: 39px;
  padding: 6px;
  .d-flex{
    display: flex;
    text-align: center;
  }
  .tabs-item{
    flex: 1;
    cursor: pointer;
  }
  .activeTab{
    background-color: #3ba17d;
  }
}
</style>
