<template>
  <Echart
    ref="myChart"
    :options="options"
    id="bottomLeftChart"
    height="250px"
    width="100%"
  ></Echart>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(val) {
        this.options = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            },
          },
          grid: {
            left: "2%",
            right: "4%",
            bottom: "14%",
            top: "16%",
            containLabel: true,
          },
          legend: {
            right: 20,
            top: 6,
            textStyle: {
              color: "#fff",
            },
            itemWidth: 12,
            itemHeight: 10,
            // itemGap: 35
          },
          xAxis: {
            type: "category",
            data: val.xData,
            axisLine: {
              lineStyle: {
                color: "white",
              },
            },
            axisLabel: {
              // interval: 0,
              // rotate: 40,
              textStyle: {
                fontFamily: "Microsoft YaHei",
              },
            },
          },

          yAxis: {
            type: "value",
            // max: "1200",
            axisLine: {
              show: false,
              lineStyle: {
                color: "white",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.3)",
              },
            },
            axisLabel: {},
          },
          dataZoom: [
            {
              show: true,
              height: 12,
              xAxisIndex: [0],
              bottom: "8%",
              start: 10,
              end: 90,
              handleIcon:
                "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
              handleSize: "110%",
              handleStyle: {
                color: "#d3dee5",
              },
              textStyle: {
                color: "#fff",
              },
              borderColor: "#90979c",
            },
            {
              type: "inside",
              show: true,
              height: 15,
              start: 1,
              end: 35,
            },
          ],
          series: [
            {
              name: "未开始",
              type: "bar",
              barWidth: "15%",
              itemStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#ff7373",
                    },
                    {
                      offset: 1,
                      color: "#ff4040",
                    },
                  ]),
                  barBorderRadius: 11,
                },
              },
              data: val.notStartedCountArr,
            },
            {
              name: "招募中",
              type: "bar",
              barWidth: "15%",
              itemStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#8bd46e",
                    },
                    {
                      offset: 1,
                      color: "#09bcb7",
                    },
                  ]),
                  barBorderRadius: 11,
                },
              },
              data: val.recruitingCountArr,
            },
            {
              name: "进行中",
              type: "bar",
              barWidth: "15%",
              itemStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#248ff7",
                    },
                    {
                      offset: 1,
                      color: "#6851f1",
                    },
                  ]),
                  barBorderRadius: 11,
                },
              },
              data: val.inProgressCountArr,
            },
            {
              name: "已结束",
              type: "bar",
              barWidth: "15%",
              itemStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#f8d351",
                    },
                    {
                      offset: 1,
                      color: "rgba(255, 188, 70, 0.8)",
                    },
                  ]),
                  barBorderRadius: 11,
                },
              },
              data: val.completedCountArr,
            },
          ],
        };

        var app = {
          currentIndex: -1,
        };
        setInterval(() => {
          var dataLen = this.options.series[0].data.length;

          // 取消之前高亮的图形
          this.$refs.myChart.chart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: app.currentIndex,
          });
          app.currentIndex = (app.currentIndex + 1) % dataLen;
          //console.log(app.currentIndex);
          // 高亮当前图形
          this.$refs.myChart.chart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: app.currentIndex,
          });
          // 显示 tooltip
          this.$refs.myChart.chart.dispatchAction({
            type: "showTip",
            seriesIndex: 0,
            dataIndex: app.currentIndex,
          });
        }, 5000);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
