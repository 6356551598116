<template>
  <div id="centerRight2">
    <dv-border-box-12>
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon class="text-icon" name="align-left"></icon>
        </span>
        <span class="text mx-2">心愿墙统计</span>
      </div>
      <div class="d-flex ai-center flex-column body-box">
        <!-- <centerRight2Chart1 :cdata="config.data"/> -->
        <dv-capsule-chart :config="config" class="dv-cap-chart"/>
      </div>
      <div class="total">总心愿数量<span>{{ total }}</span>个</div>
    </div>
    </dv-border-box-12>
  </div>
</template>

<script>
// import centerRight2Chart1 from '@/components/echart/centerRight/centerRightChart'

export default {
  data() {
    return {
      total: 0,
      config: {
        data: [
          {
            name: '未接单',
            value: 0
          },
          {
            name: '进行中',
            value: 0
          },
          {
            name: '已完成',
            value: 0
          },
          {
            name: '已评价',
            value: 0
          },
        ]
      }
    }
  },
  // components: { centerRight2Chart1 },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      let params = {
        actType: 1
      }
      const wishStateList=await this.$request.get(`/api/v1/common/getDictByCode?groupCode=civil_wish_state`)
      this.$request.get('/admin/v1/civilization/Statistics/activity/countByActState', {params}).then(res => {
        this.config.data=[]
        this.total = 0
        for(let state of wishStateList){
          if(state.dictCode==-1) continue
          const data = res.find(i => i.actState == state.dictCode)
          let count=0
          if(data) {
            count=data.count
          }
          let item={name:state.dictName,value:count}
          this.config.data.push(item)
          this.total+=count
        }
        this.config = { ...this.config }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
#centerRight2 {
  $box-height: 280px;
  $box-width: 100%;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    padding: 16px;
    height: 100%;
    width: $box-width;
    border-radius: 10px;
  }

  .text {
    color: #c3cbde;
  }

  .body-box {
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;

    .dv-cap-chart {
      width: 100%;
      height: 160px;
    }
  }
}

.total {
  font-size: 20px;
  margin-left: 20px;
  margin-top: 10px;

  span {
    font-size: 24px;
    padding: 0 10px;
    color: #39d8be;
  }
}
</style>
