<template>
  <Chart :cdata="cdata" />
</template>

<script>
import Chart from './chart.vue';

export default {
  name: 'MapChart',
  components: { Chart },
  data() {
    return{
      cdata: []
    }
  },
  mounted() {
    this.getMapData()
  },
  methods: {
    getMapData(){
      this.$request.get('/admin/v1/civilization/Statistics/org/townServiceStats').then(res=>{
        this.cdata = res.map(e=>{return { name: e.townName, value: [e.orgCount,e.totalServiceDuration]}})
      })
    },
  }
};
</script>

<style scoped>
.map-chart {
  width: 100%;
  height: 600px;
}
</style>