<template>
  <div>
    <Chart :cdata="cdata" />
    <Bar :cdata="cdata"/>
  </div>
</template>

<script>
import Chart from './chart.vue'
import Bar from './bar.vue'
export default {
  data () {
    return {
      drawTiming: null,
      cdata: {
        serviceXData: [],
        serviceYData: [],
        questionXData: [0,0,0,0],
        questionYData: ['未解决','待解决','已解决','已评价'],
      }
    }
  },
  components: {
    Chart,
    Bar
  },
  mounted () {
    this.drawTimingFn();
  },
  beforeDestroy () {
    clearInterval(this.drawTiming);
  },
  methods: {
    drawTimingFn () {
      this.setData();
      this.drawTiming = setInterval(() => {
        this.setData();
      }, 10000);
    },
    async setData () {
      let params = {
        actType: 2
      }
      
      const stateList=await this.$request.get(`/api/v1/common/getDictByCode?groupCode=civil_services_state`)
      this.$request.get('/admin/v1/civilization/Statistics/activity/countByActState', {params}).then(res => {
        this.cdata.questionXData=[]
        this.cdata.questionYData=[]
        for(let state of stateList){
          if(state.dictCode==-1) continue
          const data = res.find(i => i.actState == state.dictCode)
          let count=0
          if(data) {
            count=data.count
          }
          this.cdata.questionXData.push(count)
          this.cdata.questionYData.push(state.dictName)
        }
      })

      
      this.$request.get('/admin/v1/civilization/Statistics/activity/countByServiceType',{ params }).then(res=>{
        let arr1 = [],arr2 = []
        res.forEach(e => {
          arr1.push(e.serviceTypeName)
          arr2.push(e.count)
        })
        this.cdata.serviceXData = arr1
        this.cdata.serviceYData = arr2
      })
    }
  }
};
</script>

<style lang="scss" scoped>
</style>