<template>
  <div id="bottomLeft">
    <dv-border-box-12>
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-bar" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="text mx-2">志愿活动统计</span>
        </div>
      </div>
      <div>
        <div class="total">总活动场数<span>  {{ obj.total }} </span>场</div>
        <BottomLeftChart />
        <div class="typeTotal">
          <div class="typeTotal-item">
            <p>未开始</p>
            <span>{{ obj.notStartedCount }}</span>
          </div>
          <div class="typeTotal-item">
            <p>招募中</p>
            <span>{{ obj.recruitingCount }}</span>
          </div>
          <div class="typeTotal-item">
            <p>进行中</p>
            <span>{{ obj.inProgressCount }}</span>
          </div>
          <div class="typeTotal-item">
            <p>已结束</p>
            <span>{{ obj.completedCount }}</span>
          </div>
        </div>
      </div>
    </div>
    </dv-border-box-12>
  </div>
</template>

<script>
import BottomLeftChart from '@/components/echart/bottom/bottomLeftChart'
export default {
  components: {
    BottomLeftChart
  },
  data(){
    return{
      obj:{
        total:0,
        notStartedCount:0,
        recruitingCount:0,
        inProgressCount:0,
        completedCount:0
      }
    }
  },
  created(){
    this.getData()
  },
  methods:{
    getData(){
      let params = {
        actType: 0
      }
      this.$request.get('/admin/v1/civilization/Statistics/activity/countByActState',{ params }).then(res=>{
        res.forEach(e=>{
          if(e.actState >-1){
            this.obj.total = this.obj.total + e.count
          }
          switch (e.actState) {
            case 0:
              this.obj.notStartedCount = e.count
              break;
            case 1:
              this.obj.recruitingCount = e.count
              break;
            case 2:
              this.obj.inProgressCount = e.count
              break;
            case 3:
              this.obj.completedCount = e.count
              break;
          
            default:
              break;
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$box-height: 430px;
$box-width: 100%;
#bottomLeft {
  height: $box-height;
  width: $box-width;
  border-radius: 25px;
  .bg-color-black {
    height: 100%;
    border-radius: 10px;
    padding: 16px;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
.total{
  color: #fff;
  margin: 20px 0 0 30px;
  font-size: 18px;
  span{
    color: #32baa7;
    font-size: 24px;
  }
}
.typeTotal{
  display: flex;
  text-align: center;
  margin-top: 12px;
  .typeTotal-item{
    flex: 1;
    p{
      font-size: 18px;
      margin-bottom: 20px;
    }
    span{
      color: #ff8c1e;
      font-size: 24px;
    }
  }
}
</style>
