<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        xData: ["实践中心", "实践站", "实践所"],
        seriesData: [
          { value: 0, name: "实践中心" },
          { value: 0, name: "实践站" },
          { value: 0, name: "实践所" },
        ]
      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData(){
      this.$request.get('/admin/v1/civilization/Statistics/volunteers/countByOrg').then(res=>{
        this.cdata.seriesData[0].value = res.centerVolunteerCount
        this.cdata.seriesData[1].value = res.stationVolunteerCount
        this.cdata.seriesData[2].value = res.instituteVolunteerCount
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>