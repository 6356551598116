<!-- 群众点单统计 -->
<template>
  <Echart
    :options="options"
    height="100%"
    width="100%"
  ></Echart>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    xData: {
      type: Array,
      required: true,
      default: ()=> { return [] }
    },
    yData: {
      type: Array,
      required: true,
      default: ()=> { return [] }
    },
  },
  watch: {
    xData: {
      handler () {
        this.initEchart()
      },
      immediate: true,
      deep: true
    },
  },
  methods:{
    initEchart(){
      this.options = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          x: "8%",
          width: "88%",
          y: "8%",
        },
        legend: {
          textStyle: {
            color: "#B4B4B4"
          },
          top: "0%",
          right: "0%"
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.xData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: "评单量",
            data: this.yData,
            type: 'line',
            areaStyle: {}
          }
        ]
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>