<template>
  <div id="center">
    <dv-border-box-12>
    <div class="down">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-line" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="text mx-2">群众评单统计</span>
        </div>
      </div>
      <CenterChart :xData="massesDataX" :yData="massesDataY"/>
    </div>
  </dv-border-box-12>
  </div>
</template>

<script>
import CenterChart from '@/components/echart/center/centerChartRate'

export default {
  components: { CenterChart },
  data() {
    return {
      massesDataX: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      massesDataY: []
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    getData(){
      let params = {
        actType: 2,
        statisticsType: 'month'
      }
      this.$request.get('/admin/v1/civilization/Statistics/activity/countByDate',{ params }).then(res=>{
        let arr1 = [],arr2 = []
        res.forEach(e=>{
          arr1.push(e.date)
          arr2.push(e.count)
        })
        this.massesDataX = arr1
        this.massesDataY = arr2
      })
    },
  }
}
</script>

<style lang="scss" scoped>
#center {
  height: 250px;
  .down {
    padding: 16px;
    width: 100%;
    height: 100%;
    background-color: rgba(19, 25, 47, 0.6);
  }
}
</style>
