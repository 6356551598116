<template>
  <div id="center">
    <div class="up">
      <div
        class="bg-color-black item"
        v-for="item in titleItem"
        :key="item.title"
      >
        <p class="ml-3 colorBlue fw-b">{{ item.title }}</p>
        <div>
          <dv-digital-flop
            class="dv-dig-flop ml-1 mt-2 pl-3"
            :config="item.number"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: { },
  data() {
    return {
      titleItem: [
        {
          title: '文明实践中心',
          number: {
            number: [0],
            toFixed: 0,
            textAlign: 'center',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '文明实践所',
          number: {
            number: [0],
            toFixed: 0,
            textAlign: 'center',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '文明实践站',
          number: {
            number: [0],
            toFixed: 0,
            textAlign: 'center',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '志愿服务队',
          number: {
            number: [0],
            toFixed: 0,
            textAlign: 'center',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
      ],
    }
  },
  mounted(){
    this.getTotal()
  },
  methods:{
    getTotal(){
      this.$request.get('/admin/v1/civilization/Statistics/org/amount').then(res=>{
        this.titleItem[0].number.number[0] = res.centerAmount
        this.titleItem[1].number.number[0] = res.placeAmount
        this.titleItem[2].number.number[0] = res.stationAmount
        this.titleItem[3].number.number[0] = res.serviceTeamAmount
        this.$set(this.titleItem[0].number,'number[0]',res.centerAmount)
        this.$set(this.titleItem[1].number,'number[0]',res.placeAmount)
        this.$set(this.titleItem[2].number,'number[0]',res.stationAmount)
        this.$set(this.titleItem[3].number,'number[0]',res.serviceTeamAmount)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    p{
      font-size: 16px;
      text-align: center;
    }
    .item {
      border-radius: 6px;
      margin-top: 8px;
      width: 23%;
      height: 85px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .dv-dig-flop {
        width: 150px;
        height: 30px;
      }
    }
  }
}
</style>
