<template>
    <Echart
      :options="options"
      id="centreLeftBottom"
      height="160px"
      width="100%"
    ></Echart>
  </template>
  
  <script>
  import Echart from "@/common/echart";
  export default {
    data() {
      return {
        options: {},
        // 定义颜色
        colorList: {
          linearYtoG: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#f5b44d",
              },
              {
                offset: 1,
                color: "#28f8de",
              },
            ],
          },
          linearGtoB: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#43dfa2",
              },
              {
                offset: 1,
                color: "#28f8de",
              },
            ],
          },
          linearBtoG: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#1c98e8",
              },
              {
                offset: 1,
                color: "#28f8de",
              },
            ],
          },
          areaBtoG: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(35,184,210,.2)",
              },
              {
                offset: 1,
                color: "rgba(35,184,210,0)",
              },
            ],
          },
        },
      };
    },
    components: {
      Echart,
    },
    props: {
      cdata: {
        type: Object,
        default: () => ({}),
      },
    },
    watch: {
      cdata: {
        handler(val) {
          this.options = {
            tooltip: {
              formatter: "{b} : {c}",
            },
            grid: [
              {
                height: 110,
                top: 20,
                width: "80%",
              },
              {
                height: 110,
                width: "85%",
                top: 20,
              },
            ],
            xAxis: [
              {
                splitNumber: Math.max(...val.questionXData),
                axisLine: {
                  lineStyle: {
                    color: "#10899A",
                  },
                },
                axisTick: {
                    show: true, // 显示刻度线
                    lineStyle: {
                      color: "#10899A",
                      fontSize: 14,
                      fontFamily: "PingFangSC",
                      fontWeight: 300,
                    },
                },
                splitLine: {
                  show: false,
                },
              },
            ],
            yAxis: [
              {
                data: val.questionYData,
                axisLabel: {
                  show: true,
                },
                nameTextStyle: {
                  color: "#06D3CD",
                  fontSize: 14,
                  fontFamily: "PingFangSC",
                  fontWeight: 300,
                  padding: [0, 0, 8, 0],
                },
                axisLine: {
                  lineStyle: {
                    color: "#10899A",
                  },
                },
                axisTick: {
                  show: true,
                  lineStyle: {
                    color: "#10899A",
                    fontSize: 14,
                    fontFamily: "PingFangSC",
                    fontWeight: 300,
                  },
                },
              },
            ],
            series: [
              {
                type: "bar",
                itemStyle: {
                  barBorderRadius: 10,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: "rgba(0,222,215,0.2)",
                    },
                    {
                      offset: 1,
                      color: "#3fb1d1",
                    },
                  ]),
                },
                barWidth: 10,
                data: val.questionXData,
              },
            ],
          };
        },
        immediate: true,
        deep: true,
      },
    },
  };
  </script>
  