<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data () {
    return {
      cdata: {
        xData: [],
        notStartedCountArr: [],//未开始
        recruitingCountArr: [],//招募
        inProgressCountArr: [],//进行
        completedCountArr: [],//结束
      }
    };
  },
  components: {
    Chart,
  },
  mounted () {
    this.setData();
  },
  methods: {
    setData () {
      this.cdata = this.$options.data().cdata
      let params = {
        actType: 0
      }
      this.$request.get('/admin/v1/civilization/Statistics/activity/countActStateByServiceType',{ params }).then(res=>{
        res.forEach(e => {
          this.cdata.xData.push(e.serviceTypeName)
          this.cdata.notStartedCountArr.push(e.notStartedCount)
          this.cdata.recruitingCountArr.push(e.recruitingCount)
          this.cdata.inProgressCountArr.push(e.inProgressCount)
          this.cdata.completedCountArr.push(e.completedCount)
        })
      })
    },
  }
};
</script>

<style lang="scss" scoped>
</style>